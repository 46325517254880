/* eslint-disable prefer-destructuring */
/* eslint-disable no-fallthrough */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
// Material Dashboard 2 PRO React components
import { useDispatch } from "react-redux";
import OperationActions from "redux-react/actions/operationActions";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "components/Custom/Tables/DefaultDataTable/DataTableHeadCell";
import DataTableBodyCell from "components/Custom/Tables/DefaultDataTable/DataTableBodyCell";
import { RestartAlt } from "@mui/icons-material";
import MDTypography from "components/Basics/MDTypography";
import { IconButton, Tooltip, Card, Icon } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

function QueuesDataTable({ table, chart }) {
	const dispatch = useDispatch();

	const [queuesWithMessages, setQueuesWithMessages] = useState([]);
	const [countWithMessages, setCountWithMessages] = useState(0);
	const [queuesWithoutConsumer, setQueuesWithoutConsumer] = useState([]);
	const [countWithoutConsumer, setCountWithoutConsumer] = useState(0);

	const rightPannelContainer = useRef(null);
	const [openPannel, setOpenPannel] = useState(false);
	const [selectedQueue, setSelectedQueue] = useState({});

	// Panel functions
	const onActionClick = queue => {
		setOpenPannel(true);
		setSelectedQueue(queue);
	};

	const closePannel = () => {
		setOpenPannel(false);
		setSelectedQueue({});
	};

	/**
	 * Go to a specific page, request in DB
	 */
	const getQueuesLists = () => {
		const onSuccess = res => {
			setQueuesWithMessages(res.withMessage.listQueueWithMessage);
			setCountWithMessages(res.withMessage.countMessage);
			setQueuesWithoutConsumer(res.withoutConsumer.listQueueWithoutConsumer);
			setCountWithoutConsumer(res.withoutConsumer.countConsumer);
		};
		closePannel();
		dispatch(OperationActions.fetchQueuesMessages(chart.request.clusterCode, onSuccess));
	};

	useEffect(() => {
		getQueuesLists();
	}, [table.rows]);

	/**
	 * Display total line at the end of the table
	 */
	return (
		<MDBox>
			<MDButton onClick={() => getQueuesLists()}>
				<RestartAlt /> Refresh
			</MDButton>
			<MDBox my={3} style={{ display: "flex" }}>
				<TableContainer sx={{ boxShadow: "none" }}>
					<MDBox component="thead" style={{ display: "contents" }}>
						<MDTypography my={3} align="center">
							Queues avec messages ({countWithMessages})
						</MDTypography>
					</MDBox>
					<Table>
						<MDBox component="thead">
							<DataTableHeadCell align="center">Actions</DataTableHeadCell>
							<DataTableHeadCell align="center">Queue name</DataTableHeadCell>
							<DataTableHeadCell align="center">Nombre de message</DataTableHeadCell>
							<DataTableHeadCell align="center">Nombre de consumer</DataTableHeadCell>
							<DataTableHeadCell align="center">Vhost</DataTableHeadCell>
						</MDBox>
						<TableBody>
							{(queuesWithMessages || []).map((row, key) => (
								<TableRow onClick={() => onActionClick(row)}>
									<DataTableBodyCell>
										<IconButton onClick={() => onActionClick(row)}>
											<Tooltip placement="top" title="Details">
												<OpenInNewIcon></OpenInNewIcon>
											</Tooltip>
										</IconButton>
									</DataTableBodyCell>
									<DataTableBodyCell>{row.name}</DataTableBodyCell>
									<DataTableBodyCell>{row.messages}</DataTableBodyCell>
									<DataTableBodyCell>{row.consumers}</DataTableBodyCell>
									<DataTableBodyCell>{row.vhost}</DataTableBodyCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				{openPannel && (
					<MDBox className="rightPannelContainer" ref={rightPannelContainer}>
						<MDBox className="rightPannel" sx={{ ml: 2 }}>
							<Card sx={{ p: 2, height: "100%" }}>
								<MDBox display="flex" alignItems="center" justifyContent="space-between">
									<MDTypography variant="h4" fontWeight="medium">
										Queue :
									</MDTypography>
									<MDBox>
										<Tooltip placement="top" title="Fermer">
											<IconButton onClick={() => closePannel()}>
												<Icon>close</Icon>
											</IconButton>
										</Tooltip>
									</MDBox>
								</MDBox>
								<MDBox>Name : {selectedQueue.name}</MDBox>
								<MDBox>Vhost : {selectedQueue.vhost}</MDBox>
								<MDBox>Consumers : {selectedQueue.consumers}</MDBox>
								<MDBox mt={3}>
									<MDTypography variant="h5" fontWeight="medium">
										Messages :
									</MDTypography>
									<MDBox>Total : {selectedQueue.messages}</MDBox>
									<MDBox>Messages unacknowledged : {selectedQueue.messages_unacknowledged}</MDBox>
									<MDBox>Messages ready : {selectedQueue.messages_ready}</MDBox>
								</MDBox>
							</Card>
						</MDBox>
					</MDBox>
				)}
			</MDBox>
			<MDBox my={3}>
				<TableContainer sx={{ boxShadow: "none" }}>
					<MDBox component="thead" style={{ display: "contents" }}>
						<MDTypography my={3} align="center">
							Queues sans consumer ({countWithoutConsumer})
						</MDTypography>
					</MDBox>
					<Table>
						<MDBox component="thead">
							<DataTableHeadCell align="center">Queue name</DataTableHeadCell>
							<DataTableHeadCell align="center">Nombre de message</DataTableHeadCell>
							<DataTableHeadCell align="center">Vhost</DataTableHeadCell>
						</MDBox>
						<TableBody>
							{(queuesWithoutConsumer || []).map((row, key) => (
								<TableRow>
									<DataTableBodyCell>{row.name}</DataTableBodyCell>
									<DataTableBodyCell>{row.messages}</DataTableBodyCell>
									<DataTableBodyCell>{row.vhost}</DataTableBodyCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</MDBox>
		</MDBox>
	);
}

export default QueuesDataTable;
