/* eslint-disable no-underscore-dangle */

import { setError } from "redux-react/reducers/errorReducer";
import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	restartComponent: (component, onSuccess) => {
		let data = {
			component
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/operation/restart`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	},

	restartSeletedComponent: (seletedComponent, onSuccess) => {
		let data = {
			seletedComponent
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/operation/selected/restart`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	},

	stopComponent: (component, onSuccess) => {
		let data = {
			component
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/operation/stop`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	},

	stopSeletedComponent: (seletedComponent, onSuccess) => {
		let data = {
			seletedComponent
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/operation/selected/stop`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	},

	cleaCacheComponent: (component, onSuccess) => {
		let data = {
			component
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/operation/clear`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	},

	cleaCacheSelectedComponent: (seletedComponent, onSuccess) => {
		let data = {
			seletedComponent
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/operation/selected/clear`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	},

	getMessages: (component, onSuccess) => {
		let data = {
			component
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/operation/messages`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	},

	getMessageByID: (data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/operation/message/${data.message._id}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	},

	replayMessages: (listMessages, onSuccess) => {
		let data = {
			listMessages
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/operation/replayMessages`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	},
	runJob: (jobCode, onSuccess) => {
		let data = {
			jobCode
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/operation/runJob`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	},
	fetchQueuesMessages: (queueClusterCode, onSuccess) => {
		let data = {
			queueClusterCode
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/operation/queuesList`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	},
	getClusterSize: onSuccess => {
		let data = {};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/operation/clusterSize`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								message: err.response?.data?.error?.message ?? "none",
								type: "error"
							})
						);
					}
				})
			);
		};
	}
};

export default actions;
