/* eslint-disable prefer-destructuring */
import { Card, Grid, Icon, Divider } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

const CustomBucketChart = ({ chart, data, ...rest }) => {
	const totalCount = data[0];
	const dataIntervals = data.slice(1);

	const getIntervalLabelStatus = index => {
		if (index === 0) {
			return "Started";
		} else if (index === 1) {
			return "Missed";
		} else {
			return "Stopped";
		}
	};

	const getIntervalLabel = index => {
		if (chart.request.collection === "status") {
			return getIntervalLabelStatus(index);
		}
		if (chart.request.intervals[index] < 0) {
			return `Without ${chart.display.intervalsLabel}`;
		} else if (chart.request.intervals[index + 1]) {
			return `From ${chart.request.intervals[index]} ${chart.display.intervalsLabel} to ${chart.request.intervals[index + 1]} ${chart.display.intervalsLabel}`;
		} else {
			return `Over ${chart.request.intervals[index]} ${chart.display.intervalsLabel}`;
		}
	};

	const getIntervalColor = index => {
		if (index === 0) {
			return "success";
		} else if (index === 1) {
			return "warning";
		} else if (index === 2) {
			return "error";
		} else {
			return "info";
		}
	};

	return (
		<Grid item {...rest}>
			<Card>
				<MDBox display="flex" flexDirection="column" justifyContent="space-between" pt={1} px={2}>
					{chart.display.icon?.component && (
						<MDBox
							bgColor={chart.display.icon?.color || "info"}
							variant="gradient"
							coloredShadow={chart.display.icon?.color || "info"}
							borderRadius="xl"
							display="flex"
							justifyContent="center"
							alignItems="center"
							width="3rem"
							height="3rem"
							color="white"
							mt={-3}
						>
							<Icon fontSize="small" color="inherit">
								{chart.display.icon?.component}
							</Icon>
						</MDBox>
					)}
					<MDBox textAlign="right" lineHeight={1} mb={-3}>
						<MDTypography variant="h5" fontWeight="light" color="text">
							{chart.display.title}
						</MDTypography>
						<Divider />
					</MDBox>
					<MDBox mb={2} display="flex">
						<MDBox m={3} mb={1}>
							<MDBox lineHeight={1} mb={2} bgColor="info" borderRadius="xl" textAlign="center">
								<MDTypography variant="button" fontWeight="light" color="white">
									Total
								</MDTypography>
							</MDBox>
							{`${totalCount.total} ${chart.display.label ?? ""}`}
						</MDBox>
						{dataIntervals.map((dataCounts, index) => {
							return (
								<MDBox m={3} mb={1}>
									<MDBox
										lineHeight={1}
										mb={2}
										bgColor={getIntervalColor(index)}
										borderRadius="xl"
										textAlign="center"
										px={2}
									>
										<MDTypography variant="button" fontWeight="light" color="white">
											{getIntervalLabel(index)}
										</MDTypography>
									</MDBox>
									{`${dataCounts.total} ${chart.display.label ?? ""}`}
								</MDBox>
							);
						})}
					</MDBox>
				</MDBox>
			</Card>
		</Grid>
	);
};

export default CustomBucketChart;
