/**
 * Main application dashboard
 */
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import ChartsActions from "redux-react/actions/chartsActions";
import MDTypography from "components/Basics/MDTypography";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import OneValueChart from "pages/charts/oneValueChart";
import CustomBucketChart from "pages/charts/CustomBucketChart";
import ChartsLoader from "components/Custom/ChartsLoader";
import { useMaterialUIController } from "context";

export default function Dashboard() {
	const dispatch = useDispatch();
	const [route, getRoute] = useState("/dashboard");
	const [charts, setCharts] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	/**
	 * Get all page charts
	 */
	async function getCharts() {
		return new Promise((resolve, reject) => {
			setLoading(true);
			dispatch(ChartsActions.getPageCharts("/dashboard", [], [], res => resolve(res.charts)));
		});
	}

	/**
	 * Load and set charts
	 */
	async function loadChart() {
		let charts = await getCharts();
		setCharts(charts);
		setLoading(false);
	}

	function getChartsOrigin(dataOrigin) {
		return charts.filter(chart => chart.request.dataOrigin === dataOrigin);
	}

	useEffect(() => {
		loadChart();
	}, [route]);
	if (isLoading) {
		return (
			<DashboardLayout>
				<MDBox py={3}>
					<DashboardNavbar />
					<MDBox>
						<ChartsLoader darkMode={darkMode} />
					</MDBox>
				</MDBox>
			</DashboardLayout>
		);
	} else
		return (
			<DashboardLayout>
				<MDBox py={3}>
					<DashboardNavbar />
					<MDBox mt={2}>
						<MDTypography>Clusters</MDTypography>
						<MDBox style={{ display: "flex" }}>
							{getChartsOrigin("clusterFetch").map((chart, index) => {
								return (
									<MDBox mr={3} mt={3}>
										{chart.type === "oneValue" ? (
											<OneValueChart
												id={chart.code}
												key={index}
												chart={chart}
												data={chart.data}
												xs={2}
												md={2}
												lg={2}
											/>
										) : (
											<CustomBucketChart chart={chart} data={chart.data} />
										)}
									</MDBox>
								);
							})}
						</MDBox>
					</MDBox>
					<MDBox mt={1}>
						<MDTypography>Queues</MDTypography>
						<MDBox style={{ display: "flex" }}>
							{getChartsOrigin("queuesFetch").map((chart, index) => {
								return (
									<MDBox mr={3} mt={3}>
										{chart.type === "oneValue" ? (
											<OneValueChart
												id={chart.code}
												key={index}
												chart={chart}
												data={chart.data}
												xs={2}
												md={2}
												lg={2}
											/>
										) : (
											<CustomBucketChart chart={chart} data={chart.data} />
										)}
									</MDBox>
								);
							})}
						</MDBox>
					</MDBox>
					<MDBox mt={1}>
						<MDTypography>Status</MDTypography>
						<MDBox style={{ display: "flex" }}>
							{getChartsOrigin("legacy").map((chart, index) => {
								return (
									<MDBox mr={3} mt={3}>
										{chart.type === "oneValue" ? (
											<OneValueChart
												id={chart.code}
												key={index}
												chart={chart}
												data={chart.data}
												xs={2}
												md={2}
												lg={2}
											/>
										) : (
											<CustomBucketChart chart={chart} data={chart.data} />
										)}
									</MDBox>
								);
							})}
						</MDBox>
					</MDBox>
				</MDBox>
			</DashboardLayout>
		);
}
