/* eslint-disable prefer-destructuring */
/* eslint-disable no-fallthrough */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";
// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import lod_ from "lodash";
import i18n from "i18n";
// Material Dashboard 2 PRO React components
import { useDispatch, useSelector } from "react-redux";
import { socket } from "redux-react/middleware/ws";
import ChartsActions from "redux-react/actions/chartsActions";
import OperationActions from "redux-react/actions/operationActions";
import { display as displayItem } from "redux-react/reducers/snackBarReducer";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import MDInput from "components/Basics/MDInput";
import MDPagination from "components/Basics/MDPagination";
import MDButton from "components/Basics/MDButton";
import {
	Checkbox,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from "@mui/material";
import { RestartAlt, StopCircle, PlayCircleFilledWhite } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";
import { handleSpecialCell } from "./functions";

function DefaultDataTable({
	checkbox,
	selectedRows,
	handleSelection,
	reloadTable,
	form,
	list,
	actions,
	canSearch,
	showTotalEntries,
	table,
	pagination,
	isSorted,
	noEndBorder,
	handleChangeEntriesPerPage,
	dictionary,
	display,
	actionDeleteHandle,
	actionEditHandle,
	actionViewHandle,
	actionCheckHandle,
	attributeMyCode,
	filters,
	collection
}) {
	const dispatch = useDispatch();
	let defaultEntrie = pagination?.defaultEntrie;
	let entriesPerPage = pagination?.entriesPerPage;
	const [defaultValue, setDefaultValue] = useState(defaultEntrie ?? 10);
	const { user } = useSelector(state => state);

	const entries = entriesPerPage
		? entriesPerPage.map(el => el.toString())
		: ["5", "10", "15", "20", "25"];

	const { columns } = table;
	const [data, setData] = useState(table.rows);
	const [count, setCount] = useState(table.count);

	const sortOrder = [0, 1, -1];
	const [tableSort, setTableSort] = useState({});
	/**
	 * 1 = asc
	 * 0 = default, no sort
	 * -1 = desc
	 */

	const [attributeToDisplay, setAttributeToDisplay] = useState(display);

	/**
	 * For component
	 */
	const rightPannelContainer = useRef(null);
	const [statusSelected, setStatusSelected] = useState(null);
	const [openPannel, setOpenPannel] = useState(false);
	const [selectedComponent, setSelectedComponent] = useState({});
	const [selectedAction, setSelectedAction] = useState("");
	const [confirmAction, setConfirmAction] = useState(false);
	const [totalStatus, setTotalStatus] = useState({ total: 0, started: 0, missed: 0, stopped: 0 });

	const getSort = colID => {
		return tableSort[colID] ?? 0;
	};

	const selectNextSort = colID => {
		const actualSort = tableSort[colID] ?? 0;
		const sort = sortOrder.indexOf(actualSort);
		const nextSort = sortOrder[(sort + 1) % sortOrder.length];
		if (nextSort === 0) {
			setTableSort({});
		} else {
			setTableSort({ [colID]: nextSort });
		}
		// in case we want to sort by firstName, and after lastName, etc ... => setTableSort({ ...tableSort, [colID]: nextSort });
	};

	const { totalRow } = table;

	const tableInstance = useTable(
		{ columns, data, initialState: { pageIndex: 0 } },
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	let {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		page,
		pageOptions,
		canPreviousPage,
		canNextPage,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		setGlobalFilter,
		state: { pageIndex, pageSize, globalFilter }
	} = tableInstance;

	const [actualPage, setActualPage] = useState(1);

	// Set the default value for the entries per page when component mounts
	useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

	// Set the entries per page value based on the select value
	const setEntriesPerPage = value => setPageSize(value);

	// Handler for the input to set the pagination index
	const handleInputPagination = ({ target: { value } }) =>
		value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

	// Customized page options starting from 1
	const customizedPageOptions = pageOptions.map(option => option + 1);

	// Setting value for the pagination input
	const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

	// Search input value state
	const [search, setSearch] = useState(globalFilter);
	const [customSearch, setCustomSearch] = useState("");

	// Search input state handle
	const onSearchChange = useAsyncDebounce(value => {
		setGlobalFilter(value || undefined);
	}, 100);

	// A function that sets the sorted value for the table
	const setSortedValue = column => {
		let sortedValue;

		if (isSorted && column.isSorted) {
			sortedValue = column.isSortedDesc ? "desc" : "asce";
		} else if (isSorted) {
			sortedValue = "none";
		} else {
			sortedValue = false;
		}

		return sortedValue;
	};

	// Setting the entries starting point
	let entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

	// Setting the entries ending point
	let entriesEnd;

	if (pageIndex === 0) {
		entriesEnd = pageSize;
	} else if (pageIndex === pageOptions.length - 1) {
		entriesEnd = rows.length;
	} else {
		entriesEnd = pageSize * (pageIndex + 1);
	}

	/**
	 * When pagination is active, the entries start and end are calculated based on the actual page
	 */
	if (pagination) {
		entriesStart = defaultValue * actualPage - defaultValue + 1;
		entriesEnd = defaultValue * actualPage;

		canPreviousPage = actualPage > 1;
		canNextPage = entriesEnd < count;
	}

	const onStatusClick = status => {
		setOpenPannel(true);
		setSelectedComponent({ mySelf: status.component, myCode: status.code });
		setStatusSelected(status);
	};

	const closePannel = () => {
		setOpenPannel(false);
		setSelectedComponent({});
		setStatusSelected(null);
	};

	const openActionHandle = action => {
		setConfirmAction(true);
		setSelectedAction(action);
	};

	const closeActionHandle = () => {
		setConfirmAction(false);
		setSelectedAction("");
	};

	const getActionLabel = action => {
		switch (action) {
			case "restart":
				return (
					<MDBox>
						{i18n.t("OPERATION.LABELS.confirmRestart")} {selectedComponent.mySelf}
					</MDBox>
				);
			case "stop":
				return (
					<MDBox>
						{i18n.t("OPERATION.LABELS.confirmStop")} {selectedComponent.mySelf}
					</MDBox>
				);
			case "clearCache":
				return (
					<MDBox>
						{i18n.t("OPERATION.LABELS.confirmClear")} {selectedComponent.mySelf}
					</MDBox>
				);

			default:
				return <MDBox>STOP</MDBox>;
		}
	};

	const actionComponent = (item, action) => {
		const onSuccess = res => {
			let messageSuccess = "";
			if (action === "restart") {
				messageSuccess = i18n.t("OPERATION.ACTION.restart");
			}
			if (action === "stop") {
				messageSuccess = i18n.t("OPERATION.ACTION.stop");
			}
			if (action === "clearCache") {
				messageSuccess = i18n.t("OPERATION.ACTION.clearCache");
			}
			dispatch(
				display({
					message: messageSuccess,
					type: "success"
				})
			);
		};
		if (action === "restart") {
			dispatch(OperationActions.restartComponent(item, onSuccess));
		}
		if (action === "stop") {
			dispatch(OperationActions.stopComponent(item, onSuccess));
		}
		if (action === "clearCache") {
			dispatch(OperationActions.cleaCacheComponent(item, onSuccess));
		}
	};

	function isStatusStopped(data) {
		let lastPing = data.ping && data.ping.length > 0 ? data.ping[0] : { ts: 0 };
		if (data.missed && data.missed.length >= 3) {
			let missed = data.missed[data.missed.length - 2];
			let missedLong = Math.max(missed.ts, lastPing.ts);
			if (missedLong === missed.ts) {
				return { pingStatus: "Stopped", color: "#d32f2f" };
			}
		} else if (lod_.isNil(data.missed) && lod_.isNil(data.ping)) {
			return { pingStatus: "Stopped", color: "#d32f2f" };
		}
		return null;
	}

	function addStatusPoint(data) {
		if (collection === "status") {
			let newData = data;
			let dataFormated = [];
			let statusTotal = { total: 0, stopped: 0, started: 0, missed: 0 };
			newData.forEach(ping => {
				let lastPing = ping.ping && ping.ping.length > 0 ? ping.ping[0] : { ts: 0 };
				let lastMissed = ping.missed && ping.missed.length > 0 ? ping.missed[0] : { ts: 0 };
				let lastStatus = Math.max(lastMissed.ts, lastPing.ts);
				let pingStatus = lastStatus === lastMissed.ts ? "Missed" : "Started";
				ping.color = lastStatus === lastMissed.ts ? "#ed6c02" : "#2e7d32";
				let isStopped = isStatusStopped(ping);
				if (!lod_.isNil(isStopped)) {
					pingStatus = isStopped.pingStatus;
					ping.color = isStopped.color;
					statusTotal.stopped += 1;
				} else if (pingStatus === "Missed") {
					statusTotal.missed += 1;
				} else {
					statusTotal.started += 1;
				}
				let lastPingTime = lastStatus === lastMissed ? lastMissed.datetime : lastPing.datetime;
				ping.status = pingStatus;
				ping.lastPing = lastPingTime;
				ping.component = lastPing.ts !== 0 ? lastPing.app : lastMissed.app;
				dataFormated.push(ping);
			});
			statusTotal.total = dataFormated.length;
			setTotalStatus(statusTotal);
			setData(dataFormated);
		}
	}

	const socketPing = res => {
		let currentData = [...tableInstance.data] || [];
		let statusTotal = { total: 0, started: 0, missed: 0, stopped: 0 };
		if (collection === "status") {
			let isIn = false;
			let pingStatus = res.aliveTimeout === "check pingDelta" ? "Missed" : "Started";
			let newColor = pingStatus === "Missed" ? "#ed6c02" : "#2e7d32";

			if (!lod_.isEmpty(currentData)) {
				let newData = [];
				currentData.forEach(data => {
					if (data._id === res.fqn) {
						let isStopped = isStatusStopped(data);
						if (!lod_.isNil(isStopped)) {
							pingStatus = isStopped.pingStatus;
							newColor = isStopped.color;
						}

						if (pingStatus === "Missed" || pingStatus === "Stopped") {
							!lod_.isNil(data.missed) ? data.missed.push(res) : (data.missed = [res]);
						} else {
							!lod_.isNil(data.missed) ? data.missed.push(res) : (data.missed = [res]);
						}
						data.status = pingStatus;
						data.lastPing = res.datetime;
						data.color = newColor;
						isIn = true;
					}
					if (data.status === "Stopped") {
						statusTotal.stopped += 1;
					} else if (data.status === "Missed") {
						statusTotal.missed += 1;
					} else {
						statusTotal.started += 1;
					}
					newData.push(data);
				});
				if (!isIn) {
					newData.push({
						_id: res.fqn,
						code: res.code,
						ping: pingStatus === "Started" ? [res] : [],
						missed: pingStatus === "Missed" ? [res] : [],
						status: pingStatus,
						lastPing: res.datetime,
						color: newColor,
						component: res.app
					});
					if (pingStatus === "Stopped") {
						statusTotal.stopped += 1;
					} else if (pingStatus === "Missed") {
						statusTotal.missed += 1;
					} else {
						statusTotal.started += 1;
					}
				}
				statusTotal.total = newData.length;
				setTotalStatus(statusTotal);
				setData(newData);
			}
		}
	};

	useEffect(() => {
		socket.on("sendStatus", socketPing);
		return () => {
			socket.off("sendStatus", socketPing);
		};
	}, []);

	/**
	 * Get the maximum page that can be reached by the input
	 * @returns
	 */
	const getMaxInputValue = cc => {
		if (pagination) {
			return Math.ceil((cc || count) / defaultValue);
		} else {
			return customizedPageOptions.length;
		}
	};
	/**
	 * Go to a specific page, request in DB
	 */
	const goToPagePagination = (
		page = actualPage,
		val = defaultValue,
		searchVal = customSearch,
		sort = tableSort
	) => {
		if (list) {
			const onSuccess = res => {
				setCount(res.data.count);
				setData(res.data.paginedList);
				if (collection === "status") {
					addStatusPoint(res.data.paginedList);
				}

				if (page < 1) {
					page = 1;
				}
				if (page > getMaxInputValue(res.data.count)) {
					page = getMaxInputValue(res.data.count);
				}

				setActualPage(page);

				let maxPage = Math.ceil(res.data.count / val);
				if (maxPage > 0 && page > maxPage) {
					page = maxPage;
					goToPagePagination(page, val, searchVal);
				}
			};

			dispatch(
				ChartsActions.refreshPaginedList(
					list,
					val,
					page,
					searchVal,
					sort,
					{ ...filters, ...list.filters },
					onSuccess
				)
			);
		}
	};

	/**
	 * Update the entries per page
	 */
	function handleChangeEntries(newValue) {
		setDefaultValue(newValue);
		goToPagePagination(actualPage, newValue, customSearch);
	}
	/**
	 * Handle the previous page
	 * @param {*} e
	 */
	const handlePreviousPage = e => {
		if (pagination) {
			goToPagePagination(actualPage - 1, defaultValue, customSearch);
		} else {
			previousPage();
		}
	};
	/**
	 * Handle the next page
	 * @param {*} e
	 */
	const handleNextPage = e => {
		if (pagination) {
			goToPagePagination(actualPage + 1, defaultValue, customSearch);
		} else {
			nextPage();
		}
	};
	/**
	 * Handle the change of the page (in input)
	 * @param {*} e
	 */
	const handleChangePage = e => {
		if (pagination) {
			let { value } = e.target;
			if (value < 1) value = 1;
			goToPagePagination(value, defaultValue, customSearch);
		} else {
			handleInputPagination(e);
			handleInputPaginationValue(e);
		}
	};
	/**
	 * Get the actual page
	 * @returns
	 */
	const getActualPage = () => {
		if (pagination) {
			return actualPage;
		} else {
			return customizedPageOptions[pageIndex];
		}
	};
	/**
	 * Search
	 */
	function handleSearch(value) {
		if (pagination) {
			setCustomSearch(value);
			if (value.trim()) {
				goToPagePagination(1, defaultValue, value);
			} else {
				goToPagePagination(1, defaultValue, null);
			}
		} else {
			setSearch(search);
			onSearchChange(value);
		}
	}

	useEffect(() => {
		goToPagePagination();
	}, [reloadTable]);

	useEffect(() => {
		goToPagePagination();
	}, [tableSort]);

	useEffect(() => {
		setData(table.rows);
	}, [table.rows]);

	let actionRunAction = values => {
		const onSuccess = res => {
			dispatch(
				displayItem({
					message: `Cron ${values.name} run send to Jobs`,
					type: "success"
				})
			);
		};
		dispatch(OperationActions.runJob(values.code, onSuccess));
	};

	/**
	 * Check if the table has actions
	 */
	const hasActions =
		actionEditHandle || actionDeleteHandle || actionViewHandle || actionCheckHandle;
	/**
	 * Display total line at the end of the table
	 */
	function getTotalRow() {
		if (totalRow) {
			return (
				<TableRow>
					{Object.keys(totalRow).map((key, index) => (
						<DataTableBodyCell
							style={{
								fontWeight: "900"
							}}
							key={index}
						>
							{totalRow[key] ?? 0}
						</DataTableBodyCell>
					))}
				</TableRow>
			);
		} else {
			return null;
		}
	}
	/**
	 * ##############################
	 * Handle specials actions in props
	 * -> allow to custom actions with row values
	 * e.g : disabe, click, loadingn style
	 * ##############################
	 */
	function overrideObjectProps(action, row) {
		// custom disabled method
		const checkDisabled = act => {
			if (act.props?.disabled) {
				return act.props.disabled;
			} else if (act.props?.customdisabled) {
				return act.props.customdisabled(row);
			} else {
				return false;
			}
		};
		// custom style
		const customStyle = act => {
			let style = act.props?.style ?? {};
			if (act.props?.customstyle) {
				style = act.props.customstyle(row, style);
			}
			return style;
		};

		const customLoading = act => {
			if (act.props?.loading) {
				let loadingObject = act.props.loading(row);
				if (loadingObject.loading) {
					return loadingObject.component;
				}
			}
			return act.props?.children;
		};

		action = {
			...action,
			props: {
				...action.props,
				onClick() {
					action.props?.handleclick ? action.props?.handleclick(row) : null;
				},
				disabled: checkDisabled(action),
				style: customStyle(action),
				children: customLoading(action)
			}
		};

		if (action.props.children && lod_.isObject(action.props.children)) {
			action.props.children = overrideObjectProps(action.props.children, row);
		}

		return action;
	}

	const handleSelectRow = id => {
		if (!checkbox) return;
		let newSelectedRows = [];
		if (selectedRows.includes(id)) {
			newSelectedRows = selectedRows.filter(item => item.code !== id.code);
		} else {
			newSelectedRows = [...selectedRows, id];
		}
		handleSelection(newSelectedRows);
	};

	const selectAllRows = () => {
		if (!checkbox) return;
		let newSelectedRows = [];
		if (selectedRows.length === rows.length) {
			handleSelection(newSelectedRows);
		} else {
			rows.map(row => {
				newSelectedRows.push(row.original);
			});
			handleSelection(newSelectedRows);
		}
	};

	useEffect(() => {
		// console.log(selectedRows);
	}, [selectedRows]);

	useEffect(() => {
		goToPagePagination(0, defaultValue, customSearch);
	}, [filters]);

	const [anchorMoreVertFormMenu, setAnchorMoreVertFormMenu] = useState(null);
	const handleCloseMoreVertFormMenu = () => {
		setAnchorMoreVertFormMenu(null);
	};

	/**
	 * Menu for pagined list
	 * use dicitonary & form
	 * allow user to display fields of the collection
	 */
	const renderMoreVertFormMenu = () => {
		// Dictionary
		let objectCollection = lod_.get(dictionary, form.routeDictionary);
		if (objectCollection?.type === "level") objectCollection = objectCollection?.items;
		// Columns
		let columns = list.data.columns;
		let authorizedKey = Object.keys(objectCollection);
		let filteredColumns = [];
		// Display only authorized columns
		Object.keys(columns).filter(item => {
			item = columns[item];
			if (authorizedKey.includes(item.accessor)) {
				filteredColumns.push(item);
			}
		});
		/**
		 * Handle click on item
		 */
		const updateItemFromAttributeToDisplay = attribute => {
			let newAttributeToDisplay = [...attributeToDisplay];
			if (newAttributeToDisplay.includes(attribute)) {
				if (newAttributeToDisplay.length > 1) {
					newAttributeToDisplay = newAttributeToDisplay.filter(item => item !== attribute);
				}
			} else {
				newAttributeToDisplay.push(attribute);
			}
			setAttributeToDisplay(newAttributeToDisplay);
			// Update display in DB
			dispatch(
				ChartsActions.updateAttributeDisplayPaginedList(list.code, newAttributeToDisplay, res => {})
			);
		};
		/**
		 * Menu
		 */
		return (
			<Menu
				id="long-menu"
				anchorEl={anchorMoreVertFormMenu}
				open={Boolean(anchorMoreVertFormMenu)}
				onClose={handleCloseMoreVertFormMenu}
			>
				{Object.keys(filteredColumns).map((item, index) => {
					let itemObject = filteredColumns[item];
					return (
						<MenuItem
							disabled={
								attributeToDisplay.includes(itemObject.accessor) && attributeToDisplay.length <= 1
							}
							key={index}
							onClick={() => {
								updateItemFromAttributeToDisplay(itemObject.accessor);
							}}
						>
							<MDBox>
								<Checkbox checked={attributeToDisplay.includes(itemObject.accessor)} />
							</MDBox>
							{itemObject.Header}
						</MenuItem>
					);
				})}
			</Menu>
		);
	};

	const getActionOperation = row => {
		// console.log("called getActionOperation");
		if (collection === "platformConfig") {
			return (
				<MDBox width="max-content">
					<IconButton
						onClick={() => {
							actionEditHandle(row.values);
						}}
					>
						<Icon fontSize="medium">edit</Icon>
					</IconButton>
					<IconButton
						onClick={() => {
							actionViewHandle(row.values);
						}}
					>
						<OpenInNewIcon></OpenInNewIcon>
					</IconButton>
					{row.values.myCode && (
						<Checkbox
							checked={lod_.some(attributeMyCode, obj =>
								lod_.isEqual(obj, { _id: row.values._id, myCode: row.values.myCode })
							)}
							onChange={event => {
								actionCheckHandle(row.values, event);
							}}
						/>
					)}
				</MDBox>
			);
		} else if (collection === "messageIN" || collection === "messageOUT") {
			return (
				<MDBox width="max-content">
					<Checkbox
						checked={lod_.some(attributeMyCode, obj =>
							lod_.isEqual(obj, {
								_id: row.values._id,
								target: collection,
								mID: row.values["data.header.mID"]
							})
						)}
						onChange={event => {
							actionCheckHandle(row.values, event);
						}}
					/>

					<IconButton
						onClick={() => {
							actionViewHandle(row.values);
						}}
					>
						<OpenInNewIcon></OpenInNewIcon>
					</IconButton>
				</MDBox>
			);
		} else if (collection === "faibrikCron") {
			return (
				<MDBox width="max-content">
					<IconButton
						onClick={() => {
							actionEditHandle(row.values);
						}}
					>
						<Icon fontSize="medium">edit</Icon>
					</IconButton>
					<IconButton
						onClick={() => {
							actionDeleteHandle(row.values);
						}}
					>
						<Icon fontSize="medium">delete</Icon>
					</IconButton>
					<IconButton
						onClick={() => {
							actionRunAction(row.values);
						}}
					>
						<Icon fontSize="medium">schedule</Icon>
					</IconButton>
				</MDBox>
			);
		} else {
			return (
				<MDBox width="max-content">
					<IconButton
						onClick={() => {
							actionEditHandle(row.values);
						}}
					>
						<Icon fontSize="medium">edit</Icon>
					</IconButton>
					<IconButton
						onClick={() => {
							actionDeleteHandle(row.values);
						}}
					>
						<Icon fontSize="medium">delete</Icon>
					</IconButton>
				</MDBox>
			);
		}
	};

	/**
	 * Status table
	 * @returns {*}
	 */
	const statusTable = () => {
		return (
			<MDBox>
				<TableContainer sx={{ boxShadow: "none", display: "flex" }}>
					<MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
						<Table {...getTableProps()}>
							<MDBox component="thead">
								<DataTableHeadCell align="center">
									Started ({totalStatus.started} / {totalStatus.total})
								</DataTableHeadCell>
								<DataTableHeadCell align="center">
									Missed ({totalStatus.missed} / {totalStatus.total})
								</DataTableHeadCell>
								<DataTableHeadCell align="center">
									Stopped ({totalStatus.stopped} / {totalStatus.total})
								</DataTableHeadCell>
							</MDBox>
							<TableBody {...getTableBodyProps()}>
								<TableRow>
									<DataTableBodyCell style={{ verticalAlign: "top" }}>
										<MDBox display="flex" style={{ flexWrap: "wrap" }}>
											{data.map(
												(row, key) =>
													row.status === "Started" && (
														<MDBox
															key={key}
															style={{
																backgroundColor: "#2e7d32",
																color: "white",
																padding: "5px",
																margin: "5px"
															}}
															borderRadius="10px"
															onClick={() => {
																onStatusClick(row);
															}}
														>
															{row._id}
														</MDBox>
													)
											)}
										</MDBox>
									</DataTableBodyCell>
									<DataTableBodyCell style={{ verticalAlign: "top" }}>
										<MDBox display="flex" style={{ flexWrap: "wrap" }}>
											{data.map(
												(row, key) =>
													row.status === "Missed" && (
														<MDBox
															key={key}
															style={{
																backgroundColor: "#ed6c02",
																color: "white",
																padding: "5px",
																margin: "5px"
															}}
															borderRadius="10px"
															onClick={() => {
																onStatusClick(row);
															}}
														>
															{row._id}
														</MDBox>
													)
											)}
										</MDBox>
									</DataTableBodyCell>
									<DataTableBodyCell style={{ verticalAlign: "top" }}>
										<MDBox display="flex" style={{ flexWrap: "wrap" }}>
											{data.map(
												(row, key) =>
													row.status === "Stopped" && (
														<MDBox
															key={key}
															style={{
																backgroundColor: "#d32f2f",
																color: "white",
																padding: "5px",
																margin: "5px"
															}}
															borderRadius="10px"
															onClick={() => {
																onStatusClick(row);
															}}
														>
															{row._id}
														</MDBox>
													)
											)}
										</MDBox>
									</DataTableBodyCell>
								</TableRow>
							</TableBody>
						</Table>
					</MDBox>
					{openPannel && (
						<MDBox
							className="rightPannelContainer"
							ref={rightPannelContainer}
							style={{
								position: "absolute",
								right: "24px",
								width: "fit-content"
							}}
						>
							<MDBox className="rightPannel" sx={{ ml: 2 }}>
								<Card sx={{ p: 2, height: "100%" }}>
									<MDBox display="flex" alignItems="center" justifyContent="space-between">
										<MDBox>Status :</MDBox>
										<MDBox>
											<Tooltip placement="top" title="Fermer">
												<IconButton onClick={() => closePannel()}>
													<Icon>close</Icon>
												</IconButton>
											</Tooltip>
										</MDBox>
									</MDBox>
									<div
										style={{
											backgroundColor: statusSelected.color,
											width: "30px",
											height: "30px",
											borderRadius: "50%"
										}}
									></div>
									<MDBox>Status FQN : {statusSelected._id}</MDBox>
									<MDBox>Last ping : {statusSelected.lastPing}</MDBox>
									<MDBox>
										Component : {statusSelected.component} ({statusSelected.code})
									</MDBox>
									<MDBox display="flex" flex>
										<MDBox pl={1} mt={1}>
											<MDButton color="info">
												{" "}
												<PlayCircleFilledWhite /> START ALL
											</MDButton>
										</MDBox>
										<MDBox pl={1} mt={1}>
											<MDButton onClick={() => openActionHandle("restart")} color="info">
												<RestartAlt /> RESTART ALL
											</MDButton>
										</MDBox>
										<MDBox pl={1} mt={1}>
											<MDButton onClick={() => openActionHandle("stop")} color="info">
												<StopCircle />
												STOP ALL
											</MDButton>
										</MDBox>
										<MDBox pl={1} mt={1}>
											<MDButton onClick={() => openActionHandle("clearCache")} color="info">
												Vider le cache
											</MDButton>
										</MDBox>
									</MDBox>
								</Card>
							</MDBox>
						</MDBox>
					)}
					{confirmAction && (
						<Dialog open={confirmAction} onClose={closeActionHandle}>
							<DialogTitle>{i18n.t("OPERATION.LABELS.actionStatus")}</DialogTitle>
							<DialogContent>{getActionLabel(selectedAction)}</DialogContent>
							<DialogActions>
								<MDButton autoFocus onClick={closeActionHandle} variant="outlined" color="dark">
									{i18n.t("FORMS.cancel")}
								</MDButton>
								<MDButton
									onClick={() => actionComponent(selectedComponent, selectedAction)}
									color="info"
									variant="contained"
									autoFocus
								>
									{i18n.t("FORMS.validate")}
								</MDButton>
							</DialogActions>
						</Dialog>
					)}
				</TableContainer>
			</MDBox>
		);
	};

	if (collection === "status") {
		return statusTable();
	}

	return (
		<TableContainer sx={{ boxShadow: "none" }}>
			{entriesPerPage || canSearch ? (
				<MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
					<MDBox display="flex" alignItems="center">
						<Autocomplete
							disableClearable
							value={pageSize.toString()}
							options={entries}
							onChange={(event, newValue) => {
								if (pagination) {
									handleChangeEntries(newValue);
								} else {
									if (handleChangeEntriesPerPage) handleChangeEntriesPerPage(newValue);
									setEntriesPerPage(parseInt(newValue, 10));
								}
							}}
							size="small"
							sx={{ width: "5rem" }}
							renderInput={params => <MDInput {...params} />}
						/>
						<MDTypography variant="caption" color="secondary">
							&nbsp;&nbsp;entries per page
						</MDTypography>
					</MDBox>
					{canSearch && (
						<MDBox width="12rem" ml="auto">
							<MDInput
								placeholder="Search..."
								// value={search}
								size="small"
								fullWidth
								onChange={({ currentTarget }) => {
									handleSearch(currentTarget.value);
								}}
							/>
						</MDBox>
					)}
					{/* Only for form pages */}
					{dictionary && form && (
						<>
							<IconButton
								onClick={e => {
									setAnchorMoreVertFormMenu(e.currentTarget);
								}}
							>
								<Icon>more_vert</Icon>
							</IconButton>
							{renderMoreVertFormMenu()}
						</>
					)}
				</MDBox>
			) : null}
			<Table {...getTableProps()}>
				<MDBox component="thead">
					{headerGroups.map(headerGroup => (
						<TableRow {...headerGroup.getHeaderGroupProps()}>
							{(hasActions || actions) && (
								<DataTableHeadCell width={40} align="center">
									Actions
								</DataTableHeadCell>
							)}
							{checkbox && (
								<DataTableHeadCell width={30} align="right">
									<Checkbox
										checked={selectedRows.length === rows.length}
										onClick={() => {
											selectAllRows();
										}}
									/>
								</DataTableHeadCell>
							)}
							{headerGroup.headers.map((column, i) => {
								if (!attributeToDisplay || attributeToDisplay.includes(column.id)) {
									return (
										<DataTableHeadCell
											key={i}
											{...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
											width="auto"
											align="left"
											sorted={getSort(column.id)}
											onClick={e => {
												selectNextSort(column.id);
											}}
										>
											{column.render("Header")}
										</DataTableHeadCell>
									);
								}
								return null;
							})}
						</TableRow>
					))}
				</MDBox>
				<TableBody {...getTableBodyProps()}>
					{page.map((row, key) => {
						prepareRow(row);
						return (
							<TableRow
								key={key}
								{...row.getRowProps()}
								onClick={() => {
									handleSelectRow(row.original);
								}}
							>
								{hasActions && <DataTableBodyCell>{getActionOperation(row)}</DataTableBodyCell>}
								{checkbox && (
									<DataTableBodyCell align="right">
										<Checkbox
											checked={selectedRows.includes(row.original)}
											onClick={() => {
												handleSelectRow(row.original);
											}}
										/>
									</DataTableBodyCell>
								)}
								{row.cells.map((cell, i) => {
									if (!attributeToDisplay || attributeToDisplay.includes(cell.column.id)) {
										return (
											<DataTableBodyCell
												key={i}
												noBorder={noEndBorder && rows.length - 1 === key}
												align={cell.column.align ? cell.column.align : "left"}
												{...cell.getCellProps()}
												className={`cell_${cell.column.id.replace(".", "_")}`}
											>
												{handleSpecialCell(cell, key, dictionary, form)}
											</DataTableBodyCell>
										);
									}
									return null;
								})}
								{actions && (
									<DataTableBodyCell align="center">
										<MDBox display="flex" justifyContent="flex-end">
											{actions.map((action, index) => {
												action = overrideObjectProps(action, row);
												return <span key={index}>{action}</span>;
											})}
										</MDBox>
									</DataTableBodyCell>
								)}
							</TableRow>
						);
					})}
					{getTotalRow()}
				</TableBody>
			</Table>

			<MDBox
				display="flex"
				flexDirection={{ xs: "column", sm: "row" }}
				justifyContent="space-between"
				alignItems={{ xs: "flex-start", sm: "center" }}
				p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
			>
				{showTotalEntries && (
					<MDBox mb={{ xs: 3, sm: 0 }}>
						<MDTypography variant="button" color="secondary" fontWeight="regular">
							Showing {entriesStart} to {entriesEnd} of {count} entries
						</MDTypography>
					</MDBox>
				)}
				{(pageOptions.length > 1 || pagination) && (
					<MDPagination variant="gradient" color="info">
						{canPreviousPage && (
							<MDPagination item onClick={handlePreviousPage}>
								<Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
							</MDPagination>
						)}
						<MDBox mr={1}>
							<MDInput
								inputProps={{ type: "number", min: 1, max: getMaxInputValue() }}
								value={getActualPage()}
								onChange={handleChangePage}
							/>
						</MDBox>
						<MDBox mr={1}>
							<MDTypography
								fontSize="small"
								variant="body1"
							>{`/ ${getMaxInputValue()}`}</MDTypography>
						</MDBox>
						{canNextPage && (
							<MDPagination item onClick={handleNextPage}>
								<Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
							</MDPagination>
						)}
					</MDPagination>
				)}
			</MDBox>
		</TableContainer>
	);
}

// Setting default values for the props of DefaultDataTable
DefaultDataTable.defaultProps = {
	entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
	canSearch: false,
	showTotalEntries: true,
	isSorted: true,
	noEndBorder: false
};

export default DefaultDataTable;
