/* eslint-disable prefer-destructuring */
/* eslint-disable no-fallthrough */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useDispatch } from "react-redux";
import OperationActions from "redux-react/actions/operationActions";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import MDTypography from "components/Basics/MDTypography";
import ChartsLoader from "components/Custom/ChartsLoader";
import { RestartAlt } from "@mui/icons-material";
import { IconButton, Tooltip, Card, Icon } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useMaterialUIController } from "context";
import DataTableHeadCell from "components/Custom/Tables/DefaultDataTable/DataTableHeadCell";
import DataTableBodyCell from "components/Custom/Tables/DefaultDataTable/DataTableBodyCell";

function ClusterSizeTable({ table }) {
	const dispatch = useDispatch();

	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	const [clusters, setClusters] = useState([]);

	const rightPannelContainer = useRef(null);
	const [openPannel, setOpenPannel] = useState(false);
	const [selectedCluster, setSelectedCluster] = useState({});
	const [isLoading, setLoading] = useState(false);

	// Panel functions
	const onActionClick = cluster => {
		setOpenPannel(true);
		setSelectedCluster(cluster);
	};

	const closePannel = () => {
		setOpenPannel(false);
		setSelectedCluster({});
	};

	/**
	 * Get cluster with size
	 */
	const getClusterSize = () => {
		const onSuccessCluster = res => {
			setClusters(res.clusters);
			setLoading(false);
		};
		setLoading(true);
		dispatch(OperationActions.getClusterSize(onSuccessCluster));
	};

	useEffect(() => {
		getClusterSize();
	}, [table.rows]);

	/**
	 * if is loading return chart loader
	 */
	if (isLoading) {
		return (
			<MDBox>
				<MDButton onClick={() => getClusterSize()}>
					<RestartAlt /> Refresh
				</MDButton>
				<ChartsLoader darkMode={darkMode} />
			</MDBox>
		);
	} else
		return (
			<MDBox>
				<MDButton onClick={() => getClusterSize()}>
					<RestartAlt /> Refresh
				</MDButton>
				<MDBox my={3} style={{ display: "flex" }}>
					<TableContainer sx={{ boxShadow: "none" }}>
						<Table>
							<MDBox component="thead">
								<DataTableHeadCell align="center">Action</DataTableHeadCell>
								<DataTableHeadCell align="center">Code</DataTableHeadCell>
								<DataTableHeadCell align="center">Name</DataTableHeadCell>
								<DataTableHeadCell align="center">Description</DataTableHeadCell>
								<DataTableHeadCell align="center">Real Size (in bytes)</DataTableHeadCell>
								<DataTableHeadCell align="center">Max Size (in bytes)</DataTableHeadCell>
								<DataTableHeadCell align="center">Pourcentage utilisé</DataTableHeadCell>
							</MDBox>
							<TableBody>
								{(clusters || []).map((row, key) => (
									<TableRow>
										<DataTableBodyCell>
											<IconButton onClick={() => onActionClick(row)}>
												<Tooltip placement="top" title="Connections">
													<OpenInNewIcon></OpenInNewIcon>
												</Tooltip>
											</IconButton>
										</DataTableBodyCell>
										<DataTableBodyCell>{row.code}</DataTableBodyCell>
										<DataTableBodyCell>{row.name}</DataTableBodyCell>
										<DataTableBodyCell>{row.description}</DataTableBodyCell>
										<DataTableBodyCell>{row.realSizeInBytes}</DataTableBodyCell>
										<DataTableBodyCell>{row.clusterSizeInBytes}</DataTableBodyCell>
										<DataTableBodyCell>{row.percentage} %</DataTableBodyCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					{openPannel && (
						<MDBox className="rightPannelContainer" ref={rightPannelContainer}>
							<MDBox className="rightPannel" sx={{ ml: 2 }} style={{ height: "auto" }}>
								<Card sx={{ p: 2, height: "100%" }}>
									<MDBox style={{ position: "sticky", top: "3rem" }}>
										<MDBox display="flex" alignItems="center" justifyContent="space-between">
											<MDTypography variant="h4" fontWeight="medium">
												Cluster :
											</MDTypography>
											<MDBox>
												<Tooltip placement="top" title="Fermer">
													<IconButton onClick={() => closePannel()}>
														<Icon>close</Icon>
													</IconButton>
												</Tooltip>
											</MDBox>
										</MDBox>
										<MDBox>Nom du cluster : {selectedCluster.name}</MDBox>
										<MDBox>Code du cluster : {selectedCluster.code}</MDBox>
										{selectedCluster.connections.current &&
										selectedCluster.connections.available ? (
											<MDBox>
												<MDBox>Currents Connections: {selectedCluster.connections.current}</MDBox>
												<MDBox>
													Availables Connections: {selectedCluster.connections.available}
												</MDBox>
											</MDBox>
										) : (
											<MDTypography variant="h6">
												Cannot access connections, you are not authorized since not admin user of
												database
											</MDTypography>
										)}
									</MDBox>
								</Card>
							</MDBox>
						</MDBox>
					)}
				</MDBox>
			</MDBox>
		);
}

export default ClusterSizeTable;
